import React from 'react';
import Layout from '../components/layout';
import { navigate } from 'gatsby';
import { Button } from 'reactstrap';

const ErrorPage = () => (
  <Layout>
    <h1 className="text-center">Something went wrong</h1>
    <div className="text-center mt-3 mb-5">
      <p>
        <Button
          color="primary"
          onClick={() => {
            navigate('/apply');
          }}
        >
          Go back to your application
        </Button>
      </p>
    </div>
  </Layout>
);

export default ErrorPage;
